import React from 'react'
import './button.css'
import rightArr from '../../../../assets/images/button/rightArr.png'

const Button = ({ btnText, btnType, onClick, btnIcon }) => {
  return (
    <div
      className={`btn-parent ${btnType}`}
      onClick={() => {
        onClick()
      }}
    >
      <div className="btn-text">{btnText}</div>
      {btnIcon && (
        <img
          className={`${btnIcon ? 'icon-class-change' : 'right-icon'}`}
          src={btnIcon ?? rightArr}
        />
      )}
    </div>
  )
}

export default Button
