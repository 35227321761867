import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { CCol, CForm, CModal, CModalBody, CModalFooter, CRow } from '@coreui/react-pro'
import { useGetCourseCategoriesQuery, useGetPostApiResultQuery } from 'src/services/foaService'
import moment from 'moment'
import { postData, updateData } from 'src/services/apiService'
import './components.css'
import { useTranslation } from 'react-i18next'
import closeIcon from '../../../assets/images/modal/closeIcon.png'
import TextInputField from 'src/components/shared/components/form-fields/TextInputField'
import MultiSelectField from 'src/components/shared/components/form-fields/MultiSelectField'
import SelectBoxField from 'src/components/shared/components/form-fields/SelectBoxField'
import FormButton from 'src/components/shared/components/form-fields/FormButton'
import { useDispatch } from 'react-redux'
import { setLoader } from 'src/features/generalSlice'
import { toast } from 'react-toastify'

const AddJobAlarmModal = ({
  visibleAddModel,
  setVisibleAddModel,
  refetchAlarmList,
  isAlarmListFetched,
  isAlarmEditVal,
  setIsAlarmEditVal,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const userInfo = JSON.parse(localStorage.getItem('user'))?.userInfo
  const [empType, setEmpType] = useState(null)
  const [empLevelData, setEmpLevelData] = useState(null)
  const [districtData, setDistrictData] = useState(null)
  const [communeData, setCommuneData] = useState(null)
  const [profField, setProfField] = useState(null)
  const [benefits, setBenefits] = useState(null)
  const [company, setCompany] = useState(null)
  const [branches, setBranches] = useState(null)
  const [errorMsg, showErrorMsg] = useState(false)
  const [intErrorMsg, showIntErrorMsg] = useState(false)

  const { data: district = [] } = useGetCourseCategoriesQuery({
    type: 'district',
  })

  const { data: communes = [] } = useGetCourseCategoriesQuery({
    type: 'communes',
  })

  const { data: profFieldData = [] } = useGetCourseCategoriesQuery({
    type: 'Professional_field',
  })

  const { data: branchData = [] } = useGetCourseCategoriesQuery({
    type: 'Branch',
  })

  const { data: empTypeData = [] } = useGetCourseCategoriesQuery({
    type: 'Employment_type',
  })

  const { data: levelData = [] } = useGetCourseCategoriesQuery({
    type: 'Position_level',
  })

  const { data: benefitsData = [] } = useGetCourseCategoriesQuery({
    type: 'Benefits',
  })

  const { data: companyList = [] } = useGetPostApiResultQuery({
    url: `/job-alarm/search-company-name`,
    payload: {
      companyName: '',
    },
  })

  const checkFields = () => {
    const checkExp = !!(
      empType?.length ||
      empLevelData?.length ||
      profField?.length ||
      districtData?.length ||
      benefits?.length ||
      company?.length
    )
    showErrorMsg(!checkExp)
    return checkExp
  }

  const apiCall = (data) => {
    console.log('data...', data)
    const payload = {
      alertName: data.alertName,
      alertIntervalInDays: data.alertIntervalInDays,
      alertTime: moment().format(),
      accountId: userInfo.account_id,
      Keyword1: districtData,
      Keyword2: profField,
      Keyword3: null,
      Keyword4: empType,
      Keyword5: empLevelData,
      Keyword6: benefits,
      Keyword7: company?.[0] ?? null,
      Keyword8: communeData,
    }
    console.log('payload...', payload)
    if (data.alertIntervalInDays == 'Select') {
      showIntErrorMsg(true)
    } else {
      if (data.alertIntervalInDays != 'Select') {
        showIntErrorMsg(false)
      }
      if (checkFields()) {
        setIsLoading(true)
        dispatch(setLoader(true))
        if (isAlarmEditVal) {
          updateData(`/job-alarm/update-job-alarm/${isAlarmEditVal.alert_id}`, payload)
            .then(async (res) => {
              console.log('res...', res)
              await refetchAlarmList()
              toast.success(`Alarm has been updated.`)
              resetStateVar()
            })
            .catch((err) => {
              console.log('err...', err)
              toast.success(`Something went wrong.`)
              resetStateVar()
            })
        } else {
          console.log('payload...', payload)
          postData(`/job-alarm/create-job-alarm/`, payload)
            .then(async (res) => {
              console.log('res...', res)
              await refetchAlarmList()
              toast.success(`Alarm created.`)
              resetStateVar()
            })
            .catch((err) => {
              console.log('err...', err)
              toast.success(`Something went wrong.`)
              resetStateVar()
            })
        }
      }
    }
  }

  const resetStateVar = (e = null) => {
    reset()
    showErrorMsg(false)
    showIntErrorMsg(false)
    setVisibleAddModel(false)
    setCompany(null)
    setDistrictData(null)
    setProfField(null)
    setEmpType(null)
    setEmpLevelData(null)
    setCommuneData(null)
    setBenefits(null)
    setIsLoading(false)
    if (setIsAlarmEditVal) setIsAlarmEditVal(null)
    dispatch(setLoader(false))
  }

  const formatOptionsData = (dataArr, keyName) => {
    if (dataArr) {
      return dataArr.map((ele) => ({
        ...ele,
        value: ele.id,
        text: ele[keyName],
      }))
    }
    return []
  }

  const alertIntervalOptions = [
    {
      value: t('daily'),
      label: t('daily'),
    },
    {
      value: t('weekly'),
      label: t('weekly'),
    },
    {
      value: t('monthly'),
      label: t('monthly'),
    },
  ]

  useEffect(() => {
    // console.log('isAlarmEditVal...', isAlarmEditVal)
    if (isAlarmEditVal) {
      reset({
        alertName: isAlarmEditVal.alert_name,
        alertIntervalInDays: isAlarmEditVal.alert_interval_in_days,
      })
    } else {
      // console.log('getValues...', getValues())
      let checkBoxValues = {}
      for (const key in getValues()) {
        checkBoxValues = { ...checkBoxValues, [key]: false }
      }
      // console.log(checkBoxValues)
      reset({
        // ...checkBoxValues,
        alertName: '',
        alertIntervalInDays: 'Wählen',
      })
      // console.log('getValues...', getValues())
    }
  }, [isAlarmEditVal])

  return (
    <CModal
      className="add-job-alarm-modal"
      visible={visibleAddModel}
      size="lg"
      onClose={(e) => {
        resetStateVar(e)
      }}
    >
      <div className="job-alarm-modal-header-parent">
        <>
          <div className="job-alarm-title-parent">
            <div className="job-alarm-model-title">{t('addJobAlarmTitle')}</div>
            <img
              src={closeIcon}
              className="modal-close-icon"
              onClick={(e) => {
                resetStateVar(e)
              }}
            />
          </div>
          <div className="job-alarm-model-desc">{t('addJobAlarmDesc')}</div>
          {errorMsg && <p className="apply-form-error">{t('jobAlarmReqFields')}</p>}
        </>
      </div>
      <CModalBody>
        <CForm
          className="add-job-alarm-form"
          onSubmit={handleSubmit(
            (data) => apiCall(data),
            () => {
              console.log('errors...', errors)
            },
          )}
        >
          <TextInputField
            title={`${t('name')}*`}
            type={'text'}
            isError={errors.alertName}
            placeholder="Jobs-Alarm 1"
            modifiedRegister={register('alertName', { required: true })}
            name={'alertName'}
          />
          {errors.alertName && <p className="apply-form-error">{t('nameReq')}</p>}
          <CRow className="custom-mul-tag-style">
            <CCol md={6}>
              {empTypeData && (
                <MultiSelectField
                  optionsData={formatOptionsData(empTypeData, 'Employment_type')}
                  setterFun={setEmpType}
                  placeholder="Wählen"
                  selectionType="tags"
                  isUpdateItem={isAlarmEditVal?.employmentType}
                  label={t('employmentType')}
                />
              )}
            </CCol>
            <CCol md={6}>
              {levelData && (
                <MultiSelectField
                  optionsData={formatOptionsData(levelData, 'Position_level')}
                  setterFun={setEmpLevelData}
                  placeholder="Wählen"
                  selectionType="tags"
                  iconClass="search-icon"
                  isUpdateItem={isAlarmEditVal?.positionLevel}
                  label={t('positionLevel')}
                />
              )}
            </CCol>
          </CRow>
          <CRow className="custom-mul-tag-style">
            <CCol md={6}>
              {profFieldData && (
                <MultiSelectField
                  optionsData={formatOptionsData(profFieldData, 'Professional_field')}
                  setterFun={setProfField}
                  placeholder="Wählen"
                  selectionType="tags"
                  isUpdateItem={isAlarmEditVal?.professionalField}
                  label={t('professionalField')}
                />
              )}
            </CCol>
            <CCol md={6}>
              {district && (
                <MultiSelectField
                  optionsData={formatOptionsData(district, 'district')}
                  setterFun={setDistrictData}
                  setterFun2={setCommuneData}
                  placeholder="Wählen"
                  selectionType="tags"
                  isUpdateItem={isAlarmEditVal?.district}
                  isUpdateItem2={isAlarmEditVal?.communes}
                  iconClass="location-icon"
                  label={`${t('district')}/${t('commune')}`}
                  isCommunes={communes}
                  isAsyncSelect={true}
                />
              )}
            </CCol>
          </CRow>
          <CRow className="custom-mul-tag-style">
            <CCol md={12}>
              {benefitsData && (
                <MultiSelectField
                  optionsData={formatOptionsData(benefitsData, 'Benefits')}
                  setterFun={setBenefits}
                  placeholder="Wählen"
                  selectionType="tags"
                  isUpdateItem={isAlarmEditVal?.benefits}
                  iconClass="search-icon"
                  label={t('benefits')}
                />
              )}
            </CCol>
          </CRow>
          <CRow>
            <CCol md={12}>
              {companyList && (
                <MultiSelectField
                  optionsData={formatOptionsData(companyList, 'text_value')}
                  setterFun={setCompany}
                  isUpdateItem={isAlarmEditVal?.companyNames}
                  multiple={false}
                  placeholder="Wählen"
                  iconClass="search-icon"
                  label={t('company')}
                />
              )}
            </CCol>
          </CRow>
          {/* <CRow>
            <CCol md={12}>
              {branchData && (
                <MultiSelectField
                  optionsData={formatOptionsData(branchData, 'Branch')}
                  setterFun={setBranches}
                  placeholder="Wählen"
                  isUpdateItem={isAlarmEditVal?.branch}
                  label={t('branch')}
                />
              )}
            </CCol>
          </CRow> */}
          <CRow>
            <CCol md={12}>
              <SelectBoxField
                label={`${t('howDoYou')}*`}
                options={alertIntervalOptions}
                placeholder="Wählen"
                icon="notification-icon"
                modifiedRegister={register('alertIntervalInDays', { required: true })}
                name="alertIntervalInDays"
              />
              {intErrorMsg && <p className="apply-form-error">{t('intReq')}</p>}
            </CCol>
          </CRow>
        </CForm>
      </CModalBody>
      <CModalFooter>
        <FormButton
          buttonName={t('cancel')}
          buttonColor="whiteColor"
          onClick={(e) => {
            resetStateVar(e)
          }}
        />
        <FormButton
          buttonName={t('Job-Alert speichern')}
          buttonColor="primaryColor"
          modifyBorder={true}
          onClick={handleSubmit(
            (data) => apiCall(data),
            () => {
              console.log('errors...', errors)
            },
          )}
        />
      </CModalFooter>
    </CModal>
  )
}

export default AddJobAlarmModal
