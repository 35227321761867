import React from 'react'
import './alert.css'
import wgIcon from '../../../../assets/images/alert/warning.png'
import Button from '../button/Button'
import { useNavigate } from 'react-router-dom'

const AlertBar = ({ alertClass, alertTitle, alertDesc, btnRoute }) => {
  const navigate = useNavigate()
  const iconMapper = {
    'alert-warning': wgIcon,
  }

  const handleBtnClick = () => {
    navigate(btnRoute)
  }

  return (
    <div className={`alert-parent ${alertClass}`}>
      <img src={iconMapper[alertClass]} />
      <div className="alert-content-parent">
        <div className="alert-title">{alertTitle}</div>
        <div className="alert-desc">{alertDesc}</div>
      </div>
      <div className="alert-side-btn-parent">
        <Button btnText={'Jetzt bearbeiten'} btnType={'primary-btn'} onClick={handleBtnClick} />
      </div>
    </div>
  )
}

export default AlertBar
