import { CChart } from '@coreui/react-chartjs'
import React from 'react'
import { useTranslation } from 'react-i18next'

const BarChart = () => {
  const { t } = useTranslation()

  return (
    <CChart
      type="bar"
      //   height={'240px'}
      data={{
        labels: [
          t('jan'),
          t('feb'),
          t('mar'),
          t('apr'),
          t('may'),
          t('jun'),
          t('apr'),
          t('jul'),
          t('aug'),
          t('sep'),
          t('oct'),
          t('nov'),
          t('dec'),
        ],
        datasets: [
          {
            label: 'GitHub Commits',
            // backgroundColor: '#f87979',
            data: [],
          },
        ],
      }}
      labels="months"
      options={{
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              font: {
                weight: 'bold',
                size: 16,
              },
              color: '#D0D5DD',
            },
          },
          y: {
            min: 0,
            max: 1000,
            ticks: {
              stepSize: 200,
              font: {
                weight: 'bold',
                size: 16,
              },
              color: '#D0D5DD',
            },
          },
        },
        // maintainAspectRatio: false,
      }}
    />
  )
}

export default BarChart
