import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  success: {
    show: false,
  },
  confirmation: {
    show: false,
    confirmed: false,
    loading: false,
  },
  error: {
    show: false,
  },
}

const modelSlice = createSlice({
  name: 'model',
  initialState,
  reducers: {
    setSuccess(state, action) {
      state.success = { ...state.success, ...action.payload }
    },
    setConfirmation(state, action) {
      state.confirmation = { ...state.confirmation, ...action.payload }
    },
  },
})

export const { setSuccess, setConfirmation } = modelSlice.actions
export default modelSlice.reducer
