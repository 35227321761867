// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

// Define a service using a base URL and expected endpoints
export const channelApi = createApi({
  reducerPath: 'channelApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_URL_END_POINT}`,
    prepareHeaders: (headers, { getState }) => {
      const token = JSON.parse(localStorage.getItem('user'))?.token
      const language = getState().general.language
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
        headers.set('language', language)
        headers.set('Accept-Language', language)
      }
      return headers
    },
  }),
  tagTypes: ['Jobs', 'Course', 'Employee', 'BenefitPartner', 'InternationalLogos', 'BenefitCount'],
  endpoints: (builder) => ({
    getChannel: builder.query({
      query: () => `/channel`,
    }),
    getFoa: builder.query({
      query: () => `/foa`,
    }),
    getCourseCategories: builder.query({
      query: (data) => {
        return {
          url: `/jobs/type`,
          method: 'POST',
          body: data,
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      },
    }),
    getJobList: builder.query({
      query: ({ accountId, limit, condition, page }) =>
        `/jobs/list-by-user/${accountId}?limit=${limit}&condition=${condition}&page=${page}`,
      providesTags: ['Jobs'],
    }),
    deleteJob: builder.mutation({
      query: (id) => {
        return {
          url: `/jobs/${id}`,
          method: 'DELETE',
        }
      },
    }),
    getEmpProfile: builder.query({
      query: (data) => `/employer-profile/${data.account_id}`,
    }),
    getCourseList: builder.query({
      query: (accountId) => `/course/user-courses/${accountId}`,
      providesTags: ['Course'],
    }),
    getCourse: builder.query({
      query: (tenderId) => `/course/${tenderId}`,
      providesTags: ['Course'],
    }),
    addCourse: builder.mutation({
      query: (data) => {
        return {
          url: `/course`,
          method: 'POST',
          body: data,
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      },
    }),
    updateCourse: builder.mutation({
      query: (data) => {
        return {
          url: `/course/${data.tender_id}`,
          method: 'PUT',
          body: data,
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      },
    }),
    deleteCourse: builder.mutation({
      query: (id) => {
        return {
          url: `/course/${id}`,
          method: 'DELETE',
        }
      },
    }),
    getDashboardCount: builder.query({
      query: ({ num, account_id }) =>
        `dashboard-job-count/get-dashboard-job-course-count/${num}/${account_id}`,
    }),
    getTotalView: builder.query({
      query: (id) => `/dashboard-job-count/get-dashboard-job-click-count/${id}`,
    }),

    createEmployee: builder.mutation({
      query: (data) => {
        return {
          url: `/business/createEmployee`,
          method: 'POST',
          body: data,
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      },
      invalidatesTags: ['Employee', 'BenefitCount'],
    }),

    getEmployee: builder.query({
      query: (obj) => `/business/getAllemployees?${new URLSearchParams(obj).toString()}`,
      providesTags: ['Employee'],
    }),

    getSingleEmployee: builder.query({
      query: (accountId) => `/business/detailEmployee/${accountId}`,
      providesTags: ['Employee'],
    }),

    deleteEmployee: builder.mutation({
      query: (data) => {
        return {
          url: `/business/deleteEmployee`,
          method: 'POST',
          body: data,
        }
      },
      invalidatesTags: ['Employee', 'BenefitCount'],
    }),

    updateEmployee: builder.mutation({
      query: (data) => {
        return {
          url: `/business/updateEmployee`,
          method: 'POST',
          body: data,
        }
      },
      invalidatesTags: ['Employee'],
    }),

    addBenefitPartnerToList: builder.mutation({
      query: (data) => {
        return {
          url: `/business/addBenefitPartnerToList`,
          method: 'POST',
          body: data,
        }
      },
      invalidatesTags: ['BenefitPartner', 'BenefitCount'],
    }),

    removeBenefitPartnerFromList: builder.mutation({
      query: (data) => {
        return {
          url: `/business/removeBenefitPartnerFromList/${data.benefits_partner_id}`,
          method: 'POST',
        }
      },
      invalidatesTags: ['BenefitPartner', 'BenefitCount'],
    }),

    getBusinessCategory: builder.query({
      query: () => `/business/getCategories`,
    }),

    createBanefitPartner: builder.mutation({
      query: (data) => {
        return {
          url: `/business/admin/createBenefitsPartner`,
          method: 'POST',
          body: data,
          headers: {
            // 'Content-type': 'multipart/form-data',
          },
        }
      },
      invalidatesTags: ['BenefitPartner', 'BenefitCount'],
    }),

    getBenefitPartner: builder.query({
      query: (obj) => `/business/admin/benefitsPartnerList?${new URLSearchParams(obj).toString()}`,
      providesTags: ['BenefitPartner'],
    }),

    getBenefitPartnerDetail: builder.query({
      query: (id) => `/business/admin/benefitsPartnerDetail/${id}`,
      providesTags: ['BenefitPartner'],
    }),

    updateBenefitPartner: builder.mutation({
      query: (data) => {
        return {
          url: `/business/admin/updateBenefitsPartner`,
          method: 'POST',
          body: data,
        }
      },
      invalidatesTags: ['BenefitPartner', 'BenefitCount'],
    }),

    deleteBenefitPartner: builder.query({
      query: (id) => {
        return {
          url: `/business/admin/benefitsPartnerDelete/${id}`,
          method: 'GET',
        }
      },
      invalidatesTags: ['BenefitPartner', 'BenefitCount'],
    }),

    benefitCounts: builder.query({
      query: () => `/business/dashboard/records`,
      providesTags: ['BenefitCount'],
    }),
    getResult: builder.query({
      query: (data) => data.url,
      providesTags: ['InternationalLogos'],
    }),
    getPostApiResult: builder.query({
      query: (data) => {
        return {
          url: data.url,
          method: 'POST',
          body: data.payload,
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      },
    }),

    createInternationalLogos: builder.mutation({
      query: (data) => {
        return {
          url: `/international-partners/create-international-partners`,
          method: 'POST',
          body: data,
          headers: {
            // 'Content-type': 'multipart/form-data',
          },
        }
      },
      invalidatesTags: ['InternationalLogos'],
    }),

    updateEmployeeStatus: builder.mutation({
      query: (data) => {
        return {
          url: `/business/updateEmployeeStatus/${data.id}`,
          method: 'POST',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      },
      invalidatesTags: ['Employee', 'BenefitCount'],
    }),

    updateInternationalLogos: builder.mutation({
      query: (data) => {
        return {
          url: `/international-partners/update-international-partners/${data.id}`,
          method: 'PUT',
          body: data.form_data,
          headers: {
            // 'Content-type': 'multipart/form-data',
          },
        }
      },
      invalidatesTags: ['InternationalLogos'],
    }),

    deleteInternationalLogos: builder.mutation({
      query: (data) => {
        return {
          url: `/international-partners/delete-international-partners/${data.id}`,
          method: 'DELETE',
          headers: {
            // 'Content-type': 'multipart/form-data',
          },
        }
      },
      invalidatesTags: ['InternationalLogos'],
    }),

    toggleBenefitPartner: builder.mutation({
      query: (data) => {
        return {
          url: `/business/toggleStatusOfPartners?status=${data.status}`,
          method: 'POST',
        }
      },
      invalidatesTags: ['BenefitPartner', 'BenefitCount'],
    }),
  }),
})

export const {
  useGetChannelQuery,
  useGetCourseCategoriesQuery,
  useGetJobListQuery,
  useGetFoaQuery,
  useGetEmpProfileQuery,
  useGetCourseListQuery,
  useAddCourseMutation,
  useGetCourseQuery,
  useDeleteCourseMutation,
  useUpdateCourseMutation,
  useDeleteJobMutation,
  useGetJobQuery,
  useGetDashboardCountQuery,
  useGetTotalViewQuery,
  useCreateEmployeeMutation,
  useGetEmployeeQuery,
  useGetSingleEmployeeQuery,
  useDeleteEmployeeMutation,
  useUpdateEmployeeMutation,
  useGetBusinessCategoryQuery,
  useCreateBanefitPartnerMutation,
  useGetBenefitPartnerQuery,
  useGetBenefitPartnerDetailQuery,
  useUpdateBenefitPartnerMutation,
  useLazyDeleteBenefitPartnerQuery,
  useLazyGetBenefitPartnerDetailQuery,
  useBenefitCountsQuery,
  useGetResultQuery,
  useGetPostApiResultQuery,
  useAddBenefitPartnerToListMutation,
  useRemoveBenefitPartnerFromListMutation,
  useCreateInternationalLogosMutation,
  useUpdateInternationalLogosMutation,
  useDeleteInternationalLogosMutation,
  useUpdateEmployeeStatusMutation,
  useToggleBenefitPartnerMutation,
} = channelApi
