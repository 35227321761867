import React from 'react'
import './metricCard.css'
import disabledDropdown from '../../../../assets/images/metricCard/disabledDropdown.png'

const MetricCard = ({ cardType, heading, number, isDropIcon }) => {
  return (
    <div className={`metric-card ${cardType}`}>
      <div className="card-heading-parent">
        <div className="card-heading">{heading}</div>
        {isDropIcon && <img src={disabledDropdown} className="dropdown-icon" />}
      </div>
      <div className="card-number">{number}</div>
    </div>
  )
}

export default MetricCard
