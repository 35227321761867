import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  foaList: [],
  channelList: [],
  channelListWithData: [],
  selectedChannel: null,
  selectedPage: null,
  expandedChannelId: null,
  activeEmpFormTab: 1,
  businessUserInfo: null,
}

export const foActivitySlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    setFoaList: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.foaList = action.payload
    },
    addFoa: (state, action) => {
      state.foaList = action.payload
    },
    setChannelList: (state, action) => {
      state.channelList = action.payload
    },
    addChannelItem: (state, action) => {
      state.channelList = [...state.channelList, action.payload]
    },
    updateChannelItem: (state, action) => {
      state.channelList[
        state.channelList.findIndex((item) => item.channel_id === action.payload.channel_id)
      ] = action.payload
    },
    deleteChannelItem: (state, action) => {
      state.channelList = state.channelList.filter((ele) => ele.channel_id != action.payload)
    },
    setChannelListWithData: (state, action) => {
      state.channelListWithData = [...state.channelListWithData, action.payload]
    },
    setSelectedChannel: (state, action) => {
      state.selectedChannel = action.payload
    },
    setSelectedPage: (state, action) => {
      state.selectedPage = action.payload
    },
    setSelectedPageComponents: (state, action) => {
      state.selectedPage.components = action.payload
    },
    deleteSelectedPageComponents: (state, action) => {
      state.selectedPage.components = state.selectedPage.components.filter(
        (ele) => ele.component_id != action.payload,
      )
    },
    setExpandedChannelId: (state, action) => {
      state.expandedChannelId = action.payload
    },
    deleteSelectedChannelPage: (state, action) => {
      state.selectedChannel = state.selectedChannel.filter((ele) => ele.page_id != action.payload)
    },
    setActiveEmpFormTab: (state, action) => {
      state.activeEmpFormTab = action.payload
    },
    setBusinessUserInfo: (state, action) => {
      state.businessUserInfo = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setFoaList,
  addFoa,
  setChannelList,
  addChannelItem,
  updateChannelItem,
  deleteChannelItem,
  setChannelListWithData,
  setSelectedChannel,
  setSelectedPage,
  setSelectedPageComponents,
  setExpandedChannelId,
  deleteSelectedChannelPage,
  setActiveEmpFormTab,
  deleteSelectedPageComponents,
  setBusinessUserInfo,
} = foActivitySlice.actions

export default foActivitySlice.reducer
