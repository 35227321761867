import React from 'react'
import './tabBar.css'
import { useTranslation } from 'react-i18next'

const TabBar = ({ data, active, onTabChange }) => {
  const { t } = useTranslation()
  return (
    <div className="tabBarContainer">
      <ul className="allTabsGroup">
        {data.map((item) => (
          <li
            className={`tabItem ${item.id === active || data.length === 1 ? 'active' : ''}`}
            key={item.id}
            onClick={() => onTabChange(item.id)}
          >
            {t(item.title)}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default TabBar
