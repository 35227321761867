import React from 'react'
import './style.css'
import { useTranslation } from 'react-i18next'

const PageTitle = ({ title, content }) => {
  const { t } = useTranslation()
  return (
    <div className="commonPageContainer">
      {title && <h4 className="commonPageTitle">{t(title)}</h4>}
      {content && <p className="commonPageDescription">{t(content)}</p>}
    </div>
  )
}

export default PageTitle
