import React from 'react'

const SelectBoxField = ({
  label,
  options,
  placeholder,
  icon,
  register,
  name,
  modifiedRegister,
  isError,
  isSelectedPlaceholder = true,
  isDisabled = false,
}) => {
  // const customClassName = 'modify-select-border-style select-box-field ' + icon

  const customClassName = `modify-select-border-style select-box-field ${icon} ${
    isError && 'input-error-class'
  }`
  return (
    <div className="select-box-field-parent">
      <label className="select-box-label">{label}</label>
      <div>
        {modifiedRegister ? (
          <select
            className={customClassName}
            {...modifiedRegister}
            defaultValue={placeholder}
            disabled={isDisabled}
          >
            <option value={isSelectedPlaceholder ? placeholder : ''} disabled>
              {placeholder}
            </option>
            {options.map((ele, index) => {
              return (
                <option key={index} value={ele.value}>
                  {ele.label}
                </option>
              )
            })}
          </select>
        ) : (
          <select className={customClassName} {...register(name)} disabled={isDisabled}>
            <option value="" disabled>
              {placeholder}
            </option>
            {options.map((ele, index) => {
              return (
                <option key={index} value={ele.value}>
                  {ele.label}
                </option>
              )
            })}
          </select>
        )}
      </div>
    </div>
  )
}

export default SelectBoxField
