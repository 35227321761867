import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  language: 'de',
  breadcrumb: ['dashboard'],
  isOpenSubSideBar: false,
  isProfCompeleted: false,
  isPremOrPro: false,
}

const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setLoader(state, action) {
      state.isLoading = action.payload
    },
    setLanguage(state, action) {
      state.language = action.payload
    },
    setBreadcrumb(state, action) {
      state.breadcrumb = action.payload
    },
    setIsOpenSubSideBar(state, action) {
      state.isOpenSubSideBar = action.payload
    },
    setIsProfCompeleted(state, action) {
      state.isProfCompeleted = action.payload
    },
    setIsUserPremOrPro(state, action) {
      state.isPremOrPro = action.payload
    },
  },
})

export const {
  setLoader,
  setLanguage,
  setBreadcrumb,
  setIsOpenSubSideBar,
  setIsProfCompeleted,
  setIsUserPremOrPro,
} = generalSlice.actions
export default generalSlice.reducer
