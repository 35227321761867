import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/dist/query'
import foActivitySlice from './features/foActivitySlice'
import mainSlice from './features/mainSlice'
import { channelApi } from './services/foaService'
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'
import generalSlice from './features/generalSlice'
import modelSlice from './features/modelSlice'

const persistConfig = {
  key: 'root',
  version: 1,
  storage: storageSession,
  blacklist: [channelApi.reducerPath],
}

const rootReducer = combineReducers({
  [channelApi.reducerPath]: channelApi.reducer,
  // [foaApi.reducerPath]: foaApi.reducer,
  foaActivity: foActivitySlice,
  mainValue: mainSlice,
  general: generalSlice,
  model: modelSlice,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(channelApi.middleware),
  // .concat(foaApi.middleware),
})

setupListeners(store.dispatch)
