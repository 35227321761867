import React, { useEffect } from 'react'
import { CCol, CRow } from '@coreui/react-pro'
import WatchList from './WatchList'
import JobAlarm from './JobAlarm'
import Widget from 'src/views/shared/components/Widget'
import { faChartSimple, faSuitcase, faLock } from '@fortawesome/free-solid-svg-icons'
import { useGetPostApiResultQuery, useGetResultQuery } from 'src/services/foaService'
import { useDispatch } from 'react-redux'
import { setLoader } from 'src/features/generalSlice'
import { useTranslation } from 'react-i18next'
import PageTitle from 'src/components/shared/components/pageTitle/PageTitle'
import TabBar from 'src/components/shared/components/tabBar/TabBar'
import MetricCard from 'src/components/shared/components/metricCard/MetricCard'
import './candidate.css'

const CandidateDashboard = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const userInfo = JSON.parse(localStorage.getItem('user'))?.userInfo
  const {
    data: alarmList = null,
    isSuccess: isAlarmListFetched,
    refetch: refetchJobAlarmList,
  } = useGetResultQuery({
    url: `/job-alarm/get-job-alarm/${userInfo.account_id}`,
  })

  const { data: appCount, isSuccess: isAppCountFetched } = useGetResultQuery({
    url: `/form/applicationCount/${userInfo.account_id}`,
  })

  const {
    data: watchList,
    isSuccess: isWatchListFetched,
    refetch: refetchFavJobs,
  } = useGetResultQuery({
    url: `/watchList/jobfavorite/${userInfo.account_id}`,
  })

  const { data: watchListCount, isSuccess: isWatchListCountFetched } = useGetPostApiResultQuery({
    url: `/watchList/favoriteCount`,
    payload: {
      accountId: userInfo.account_id,
      label: 'job',
    },
  })

  const setWatchListCount = (params) => {
    if (watchListCount) return watchListCount.favoriteCount[0].count
    return 0
  }

  useEffect(() => {
    dispatch(setLoader(true))
  }, [])

  useEffect(() => {
    if (isAlarmListFetched && isAppCountFetched && isWatchListFetched && isWatchListCountFetched) {
      dispatch(setLoader(false))
    } else {
      dispatch(setLoader(true))
    }
  }, [isAlarmListFetched, isAppCountFetched, isWatchListFetched, isWatchListCountFetched])

  return (
    <>
      <PageTitle title={`${t('hello')}`} content={t('canDashDesc')} />
      <TabBar data={[{ id: 1, title: 'genInfo' }]} />
      <CRow className="grid-card-row-parent">
        <CCol xs={12} md={4} className="grid-card-parent grid-card-mobile-parent">
          <MetricCard
            cardType={'enable-card'}
            heading={t('applications')}
            number={appCount?.applicationCount?.count ?? 0}
          />
        </CCol>
        <CCol xs={12} md={4} className="grid-card-parent grid-card-mobile-parent">
          <MetricCard
            cardType={'enable-card'}
            heading={t('Gespeicherte Jobs')}
            number={setWatchListCount()}
          />
        </CCol>
        <CCol xs={12} md={4} className="grid-card-parent grid-card-mobile-parent">
          <MetricCard cardType={'disabled-card'} heading={t('newJobOffers')} number={'0'} />
        </CCol>
      </CRow>
      <CRow>
        <CCol md={6}>
          <WatchList watchList={watchList} refetchFavJobs={refetchFavJobs} />
        </CCol>
        <CCol md={6}>
          <JobAlarm alarmList={alarmList} refetchAlarmList={refetchJobAlarmList} />
        </CCol>
      </CRow>
    </>
  )
}

export default CandidateDashboard
