export const USERROLES = ['business_user', 'super_admin', 'user', 'customer_success']
export const EDITJOBCMP = 'EditJob'
export const PROFEMPCMP = 'profEmpCmp'
export const FURTHEREDUPROCMP = 'furtherEduProCmp'
export const ALERTOBJ = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'light',
}
export const STATUS = ['Online', 'Expired', 'Pending']
export const STATUSCLASS = ['online', 'in-progress', 'in-progress']
export const PERPAGE = 10
export const DISABLEDTABS = ['Candidates profile']

export const _STATUS = {
  0: 'inProgress',
  1: 'active',
  2: 'deactive',
  3: 'tobe_checked',
  4: 'invited',
  6: 'online',
  7: 'expired',
  8: 'subscriptionExpired',
  9: 'mailDeactivated',
}

export const _STATUSCLASSNAME = {
  0: 'status-in-progress',
  1: 'status-success',
  2: 'status-deactive',
  3: 'status-checked',
  4: 'status-invited',
  5: 'status-reviewed',
  6: 'status-online',
  7: 'status-expired',
  8: 'status-deactive',
  9: 'status-deactive',
}

export const LANGS = [
  { id: 1, title: 'DE', fullTitle: 'Deutsch' },
  { id: 2, title: 'IT', fullTitle: 'Italienisch ' },
  // { id: 2, title: 'EN',fullTitle:'' },
]
