import { CCol, CRow } from '@coreui/react-pro'
import { faBell, faPen, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import Button from 'src/components/shared/components/button/Button'
import { setLoader } from 'src/features/generalSlice'
import { deleteData } from 'src/services/apiService'
import AddJobAlarmModal from 'src/views/shared/components/AddJobAlarmModal'
import Swal from 'sweetalert2'
import plusIcon from '../../../assets/images/common-icons/plus.png'
import editIcon from '../../../assets/images/common-icons/edit.png'
import deleteIcon from '../../../assets/images/common-icons/delete.png'
import searchIcon from '../../../assets/images/common-icons/search.png'
import './candidate.css'
import { setConfirmation, setSuccess } from 'src/features/modelSlice'

const JobAlarm = ({ alarmList, refetchAlarmList }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [visibleAddModel, setVisibleAddModel] = useState(false)
  const [isEditVal, setIsEditVal] = useState(null)
  const { confirmation } = useSelector((state) => state.model)
  const [id, setId] = useState(0)

  const handleAddClick = () => {
    setVisibleAddModel(true)
  }

  const handleEdit = (alertItem) => {
    setIsEditVal(alertItem)
    setVisibleAddModel(true)
  }

  useEffect(() => {
    if (confirmation.confirmed && confirmation.show && id) {
      handleDelete()
    }
  }, [confirmation])

  const handleDelete = async () => {
    try {
      // const confirm = await Swal.fire({
      //   title: 'Are you sure?',
      //   text: "You won't be able to revert this!",
      //   icon: 'warning',
      //   showCancelButton: true,
      //   confirmButtonColor: '#3085d6',
      //   cancelButtonColor: '#d33',
      //   confirmButtonText: 'Yes, delete it!',
      // })

      // if (confirm.isConfirmed) {
      dispatch(setLoader(true))
      deleteData(`/job-alarm/delete-job-alarm/${id}`)
        .then(async (res) => {
          console.log('res...', res)
          await refetchAlarmList()
          // toast.success(`Alarm has been removed.`)
          dispatch(setConfirmation({ confirmed: false, show: false, loading: false }))
          dispatch(setSuccess({ show: true }))
          setId(0)
          dispatch(setLoader(false))
        })
        .catch((err) => {
          console.log('err...', err)
          dispatch(setLoader(false))
          // toast.error('Something went wrong.')
          dispatch(setConfirmation({ confirmed: false, show: false, loading: false }))
          setId(0)
        })
      // }
    } catch (error) {
      dispatch(setLoader(false))
      console.log(error.message)
      // toast.error('Something went wrong.')
      dispatch(setConfirmation({ confirmed: false, show: false, loading: false }))
      setId(0)
    }
  }

  const handleDeleteClick = (id) => {
    setId(id)
    dispatch(setConfirmation({ show: true }))
  }

  const displayDiv = (ele) => {
    if (ele === 'btn' || ele === 'divider') {
      return alarmList && alarmList.length !== 0
    }
    if (ele === 'blankDesc') {
      return alarmList && alarmList.length === 0
    }
  }

  const blankLayout = () => (
    <div className="watchlist-blank-parent">
      <div className="common-blank-icon-parent">
        <img src={searchIcon} />
      </div>
      <div className="common-blank-desc-title">Keine Job-Alerts gefunden</div>
      <div className="common-blank-desc-sub-desc">
        Du hast noch keinen Job-Alert erstellt. Beginne jetzt und erstelle Deinen individuellen
        Job-Alert.
      </div>
      <Button
        btnText={t('Job-Alert erstellen')}
        btnType="primary-btn"
        onClick={() => {
          handleAddClick()
        }}
        btnIcon={plusIcon}
      />
    </div>
  )

  const listView = () => {
    return (
      <div className="watchlist-list-view-parent">
        {alarmList.map((ele, idx) => (
          <div key={idx}>
            <CRow className="job-alarm-item-parent">
              <CCol md={4} className="job-alarm-name">
                {ele.alert_name}
              </CCol>
              <CCol md={6}></CCol>
              <CCol md={2}>
                <img
                  className="job-alarm-edit-icon cursor-pointer"
                  src={editIcon}
                  onClick={() => {
                    handleEdit(ele)
                  }}
                />
                <img
                  className="cursor-pointer"
                  src={deleteIcon}
                  onClick={() => {
                    handleDeleteClick(ele.alert_id)
                  }}
                />
              </CCol>
            </CRow>
            <CRow className="job-alarm-tags-parent">
              <CCol md={12} className="job-alarm-tag-item-col">
                {ele.jobAlarm &&
                  ele.jobAlarm.map((keyEle, keyIdx) => (
                    <div key={keyIdx} className="job-alarm-tag">
                      {keyEle.slug}
                    </div>
                  ))}
              </CCol>
            </CRow>
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className="candidate-background bordered-bg candidate-list-view-background">
      <AddJobAlarmModal
        visibleAddModel={visibleAddModel}
        setVisibleAddModel={setVisibleAddModel}
        refetchAlarmList={refetchAlarmList}
        isAlarmEditVal={isEditVal}
        setIsAlarmEditVal={setIsEditVal}
      />
      <div className={`watchlist-header-parent ${displayDiv('blankDesc') && 'modify-flex-style'}`}>
        <h5 className="watchlist-title">{t('jobAlarm')}s</h5>
        {displayDiv('btn') && (
          <h5 className="watchlist-title cursor-pointer">
            <Button
              btnText={t('addTo')}
              btnType="primary-btn"
              onClick={() => {
                handleAddClick()
              }}
              btnIcon={plusIcon}
            />
          </h5>
        )}
        {displayDiv('blankDesc') && (
          <div className="common-blank-desc">Hier siehst Du Deine erstellen Job-Alerts</div>
        )}
      </div>
      {displayDiv('divider') && <div className="common-table-divider"></div>}
      {alarmList && alarmList.length !== 0 ? (
        listView()
      ) : alarmList && alarmList.length === 0 ? (
        blankLayout()
      ) : (
        <></>
      )}
    </div>
  )
}

export default JobAlarm
