import { CButton } from '@coreui/react-pro'
import React from 'react'

const FormButton = ({
  buttonName,
  buttonColor,
  onChange,
  onClick,
  modifyBorder,
  isDisabled = false,
}) => {
  const customClassName = 'form-button-field ' + buttonColor
  return (
    <div>
      <CButton
        className={`${customClassName} ${modifyBorder ? 'modify-border-style' : ''} ${
          isDisabled ? 'disable-pointer-event' : ''
        }`}
        onChange={onChange}
        onClick={onClick}
      >
        {buttonName}
      </CButton>
    </div>
  )
}

export default FormButton
