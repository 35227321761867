import React, { useState, useEffect } from 'react'
import './dashboard.css'
import Switch from 'react-switch'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSuitcase,
  faChartSimple,
  faHouseChimney,
  faGraduationCap,
  faHeart,
  faUser,
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons'
import { CChart } from '@coreui/react-chartjs'
import {
  CCol,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CFormSelect,
  CFormSwitch,
  CRow,
} from '@coreui/react-pro'
import {
  useBenefitCountsQuery,
  useGetDashboardCountQuery,
  useGetResultQuery,
  useGetTotalViewQuery,
} from 'src/services/foaService'
import { useTranslation } from 'react-i18next'
import AlertBar from 'src/components/shared/components/alertBar/AlertBar'
import MetricCard from 'src/components/shared/components/metricCard/MetricCard'
import BarChart from 'src/components/shared/components/barChart/BarChart'
import Notifications from './Notifications'
import PageTitle from 'src/components/shared/components/pageTitle/PageTitle'

const BusinessUserDashboard = () => {
  // const [checked1, setChecked1] = useState(true)
  // const [checked2, setChecked2] = useState(false)
  // const [checked3, setChecked3] = useState(false)
  const { t } = useTranslation()
  const userInfo = JSON.parse(localStorage.getItem('user'))?.userInfo

  const userName = JSON.parse(localStorage.getItem('user'))?.userInfo.userName
  const account_id = JSON.parse(localStorage.getItem('user'))?.userInfo.account_id

  const { data: [{ Total_Jobs: totalJobs }] = [{ totalJobs: '0' }] } = useGetDashboardCountQuery({
    num: 1,
    account_id,
  })
  const { data: [{ Total_Jobs: totalCourse }] = [{ totalCourse: '0' }] } =
    useGetDashboardCountQuery({ num: 2, account_id })

  const { data: [{ Total_count: totalViews }] = [{ totalViews: '0' }] } =
    useGetTotalViewQuery(account_id)

  const { data: { profilePercentage: profCmp } = { profCmp: '0' } } = useGetResultQuery({
    url: `/employer-profile/profile-percentage/${account_id}`,
  })

  const { data: { records } = { records: {} } } = useBenefitCountsQuery()

  const catdType = () => {
    return !userInfo.userStatus ? 'disabled-card' : 'enable-card'
  }

  const updateUserStatus = () => {
    return userInfo.userStatus
  }

  useEffect(() => {
    updateUserStatus()
  }, [userInfo])

  return (
    <div className="businessDashboard container">
      <PageTitle title={t('dashboard')} />
      {!updateUserStatus() && (
        <AlertBar
          alertClass={'alert-warning'}
          alertTitle={t('note')}
          alertDesc={t('alertDesc')}
          btnRoute={'/business-user/unternehmen/arbeitgeberprofil/'}
        />
      )}
      <CRow>
        <CCol xs={12} md={4} className="grid-card-parent grid-card-mobile-parent">
          <MetricCard cardType={catdType()} heading={t('jobsOnline')} number={totalJobs ?? 0} />
        </CCol>
        <CCol xs={12} md={4} className="grid-card-parent grid-card-mobile-parent">
          <MetricCard cardType={catdType()} heading={t('totalViews')} number={totalViews ?? 0} />
        </CCol>
        <CCol xs={12} md={4} className="grid-card-parent grid-card-mobile-parent">
          <MetricCard
            cardType={catdType()}
            heading={t('profileCompleteness')}
            number={profCmp ?? 0}
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol xs={12} md={4} className="grid-card-mobile-parent">
          <MetricCard cardType={catdType()} heading={t('onlineCourse')} number={totalCourse ?? 0} />
        </CCol>
        <CCol xs={12} md={4} className="grid-card-mobile-parent">
          <MetricCard
            cardType={catdType()}
            heading={t('benefitsAccount')}
            number={records?.active_benefits ?? 0}
          />
        </CCol>
        <CCol xs={12} md={4} className="grid-card-mobile-parent">
          <MetricCard cardType={'disabled-card'} heading={t('newCandidate')} number={'0'} />
        </CCol>
      </CRow>

      <div className="row mt-4">
        <div className="col-md-8">
          <div className="chart-heading pb-4 mb-2">
            <h4 className="title pt-2">{t('jobStatistics')}</h4>
            <CDropdown className="chart-dropdown-parent">
              <CDropdownToggle color="secondary" className="px-4">
                {t('year')}
              </CDropdownToggle>
              <CDropdownMenu>
                <CDropdownItem href="#">{t('week')}</CDropdownItem>
                <CDropdownItem href="#">{t('months')}</CDropdownItem>
                <CDropdownItem href="#">{t('year')}</CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
          </div>
          <BarChart />
        </div>
        <div className="col-md-4">
          <div className="chart-heading pb-4">
            <h4 className="title pt-2">{t('notifications')}</h4>
          </div>
          <Notifications />
        </div>
      </div>

      {/* 
      <h4 className="title mt-5">Your Packeage</h4>

      <div className="cards row">
        <div className="single-card packeage col-md-4">
          <div className="">
            <h3>Basic Package</h3>
            <p>Ends at 31.12.2023</p>
          </div>
          <div className="">
            <Switch
              onChange={() => setChecked1(!checked1)}
              uncheckedIcon={false}
              checkedIcon={false}
              checked={checked1}
              offColor={'#eeeeee'}
              onColor={'#eeeeee'}
              offHandleColor={'#9b9797'}
              onHandleColor={'#f4d300'}
              width={75}
              height={38}
            />
          </div>
        </div>

        <div className="single-card packeage col-md-4">
          <div className="">
            <h3>Banefit</h3>
            <p>Learn more</p>
          </div>
          <div className="">
            <Switch
              onChange={() => setChecked2(!checked2)}
              uncheckedIcon={false}
              checkedIcon={false}
              checked={checked2}
              offColor={'#eeeeee'}
              onColor={'#eeeeee'}
              offHandleColor={'#9b9797'}
              onHandleColor={'#f4d300'}
              width={75}
              height={38}
            />
          </div>
        </div>

        <div className="single-card packeage col-md-4">
          <div className="">
            <h3>Talentpool</h3>
            <p>Learn more</p>
          </div>
          <div className="">
            <Switch
              onChange={() => setChecked3(!checked3)}
              uncheckedIcon={false}
              checkedIcon={false}
              checked={checked3}
              offColor={'#eeeeee'}
              onColor={'#eeeeee'}
              offHandleColor={'#9b9797'}
              onHandleColor={'#f4d300'}
              width={75}
              height={38}
            />
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default BusinessUserDashboard
