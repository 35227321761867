import { faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const Widget = ({ count, title, icon, isDisabled = false }) => {
  return (
    <div
      className={`h-100 p-3 px-5 rounded d-flex align-items-center justify-content-between widget-parent ${
        isDisabled ? 'disable_div' : ''
      }`}
    >
      <div className="mt-4">
        <h4 className="fs-2 fw-bolder">{count}</h4>
        <p className="fs-4">{title}</p>
      </div>
      <div className="">
        <FontAwesomeIcon size="3x" icon={icon} />
      </div>
    </div>
  )
}

export default Widget
