import { CCol, CRow } from '@coreui/react-pro'
import { faHeart, faLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'src/components/shared/components/button/Button'
import './candidate.css'
import nextPageIcon from '../../../assets/images/common-icons/nextPage.png'
import locationIcon from '../../../assets/images/common-icons/location.png'
import favIcon from '../../../assets/images/common-icons/fav.png'
import searchIcon from '../../../assets/images/common-icons/search.png'
import { postData } from 'src/services/apiService'
import { useDispatch } from 'react-redux'
import { setLoader } from 'src/features/generalSlice'

const WatchList = ({ watchList, refetchFavJobs }) => {
  const { t } = useTranslation()
  const userInfo = JSON.parse(localStorage.getItem('user'))?.userInfo
  const dispatch = useDispatch()

  const filterDistrict = (distArr) => {
    if (distArr && distArr.length > 0) {
      return distArr.reduce((prev, curr, idx) => {
        const comma = idx !== 0 ? ', ' : ''
        prev = prev + comma + curr.name
        return prev
      }, '')
    }
  }

  const isJobAssigned = (val) => {
    return val === 2
  }

  const handleFavClick = (tenderId) => {
    dispatch(setLoader(true))
    const payload = {
      accountId: userInfo.account_id,
      tenderId: tenderId,
      favorite: false,
    }

    postData('/watchList/markFavorite', payload)
      .then(async (res) => {
        await refetchFavJobs()
        dispatch(setLoader(false))
      })
      .catch((err) => {
        console.log('err...', err)
        dispatch(setLoader(false))
      })
  }

  const blankLayout = () => (
    <div className="watchlist-blank-parent">
      <div className="common-blank-icon-parent">
        <img src={searchIcon} />
      </div>
      <div className="common-blank-desc-title">Keine Jobs gefunden</div>
      <div className="common-blank-desc-sub-desc">
        Du hast noch keine Jobs zur Merkliste hinzugefügt. Finde jetzt interessante Jobs und füge
        sie zu Deiner Merkliste hinzu!
      </div>
      <Button
        btnText={t('searchJobs')}
        btnType="primary-btn"
        onClick={() => {}}
        btnIcon={nextPageIcon}
      />
    </div>
  )

  const displayDiv = (ele) => {
    if (ele === 'btn' || ele === 'divider') {
      return watchList && watchList.length !== 0
    }
    if (ele === 'blankDesc') {
      return watchList && watchList.length === 0
    }
  }

  const listView = () => {
    return (
      <div className="watchlist-list-view-parent">
        {watchList.map((ele, idx) => (
          <CRow key={idx} className="watchlist-list-item-parent">
            <CCol md={2}>
              <img
                className="bookmarks-placeholder-img"
                src={`${process.env.REACT_APP_URL_END_POINT}/${ele.companyLogo}`}
              ></img>
            </CCol>
            <CCol md={4} className="bookmark-desc-parent">
              <div className="bookmarks-item-title">
                {ele.jobTitle}{' '}
                {isJobAssigned(+ele.isPublished) && (
                  <span className="bookmark-title-lable">{t('assigned')}</span>
                )}
              </div>
              <div className="watchlist-desc-parent">
                <img src={locationIcon} className="watchlist-location-icon" />
                <div className="bookmarks-item-desc">{ele.district}</div>
              </div>
            </CCol>
            <CCol md={5}></CCol>
            <CCol md={1} className="bookmarks-icon-parent">
              <img
                src={favIcon}
                className="bookmarks-fav-icon"
                onClick={() => {
                  handleFavClick(ele.tenderId)
                }}
              />
            </CCol>
          </CRow>
        ))}
      </div>
    )
  }

  return (
    <div className="candidate-background bordered-bg candidate-list-view-background">
      <div className={`watchlist-header-parent ${displayDiv('blankDesc') && 'modify-flex-style'}`}>
        <h5 className="watchlist-title">{t('Merklists')}</h5>
        {displayDiv('btn') && (
          <h5 className="watchlist-title">
            <Button
              btnText={t('showAll')}
              btnType="primary-btn"
              onClick={() => {}}
              btnIcon={nextPageIcon}
            />
          </h5>
        )}
        {displayDiv('blankDesc') && (
          <div className="common-blank-desc">
            Hier siehst du alle Jobs die Du in die Merkliste aufgenommen hast
          </div>
        )}
      </div>
      {displayDiv('divider') && <div className="common-table-divider"></div>}
      {watchList && watchList.length !== 0 ? (
        listView()
      ) : watchList && watchList.length === 0 ? (
        blankLayout()
      ) : (
        <></>
      )}
    </div>
  )
}

export default WatchList
