import React, { Component, Suspense, useEffect } from 'react'
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom'
import './scss/style.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-toastify/dist/ReactToastify.css'
import ProtectedLayout from './components/ProtectedLayout'
import Dashboard from './views/dashboard/Dashboard'
import { useAuth } from './hooks/useAuth'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const SignIn = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

// Email App
const EmailApp = React.lazy(() => import('./views/apps/email/EmailApp'))

export const App = () => {
  const { logoutAllTabs, loginAllTabs } = useAuth()

  useEffect(() => {
    logoutAllTabs()
    loginAllTabs()
  }, [])

  return (
    // <HashRouter>
    <Suspense fallback={loading}>
      <Routes>
        {/* <Route path="/" element={<Navigate to="/login" replace />} /> */}
        <Route exact path="/login" name="Login Page" element={<SignIn />} />
        <Route exact path="/register" name="Register Page" element={<Register />} />
        <Route exact path="/404" name="Page 404" element={<Page404 />} />
        <Route exact path="/500" name="Page 500" element={<Page500 />} />
        <Route path="/apps/email/*" name="Email App" element={<EmailApp />} />
        <Route element={<ProtectedLayout />}>
          <Route path="*" name="Home" element={<DefaultLayout />} />
        </Route>
      </Routes>
    </Suspense>
    // </HashRouter>
  )
}

export default App
