import React from 'react'
import './dashboard.css'
import bellIcon from '../../assets/images/notifications/bell.png'
import { useTranslation } from 'react-i18next'

const Notifications = () => {
  const { t } = useTranslation()

  const blankLayout = () => (
    <div className="notifications-blank-view-parent">
      <div className="notifications-blank-content-parent">
        <img className="notifications-blank-bell-icon" src={bellIcon} />
        <div className="notifications-blank-main-desc">Keine</div>
        <div className="notifications-blank-main-desc">{t('notifications')}</div>
        <div className="notifications-blank-sub-desc">Sie haben keine neuen</div>
        <div className="notifications-blank-sub-desc">{t('notifications')}</div>
      </div>
    </div>
  )

  return <div className="notifications-parent">{blankLayout()}</div>
}

export default Notifications
