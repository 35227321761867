import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sidebarShow: true,
  asideShow: false,
  theme: 'default',
  sidebarUnfoldable: false,
}

export const mainSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    setSidebarShow: (state, action) => {
      state.sidebarShow = action.payload.sidebarShow
    },
    setAsideShow: (state, action) => {
      state.asideShow = action.payload.asideShow
    },
    setTheme: (state, action) => {
      console.log('action...', action)
      state.theme = action.payload.theme
    },
    setSidebarUnfoldable: (state, action) => {
      state.sidebarUnfoldable = action.payload.sidebarUnfoldable
    },
  },
})

// Action creators are generated for each case reducer function
export const { setSidebarShow, setAsideShow, setTheme, setSidebarUnfoldable } = mainSlice.actions

export default mainSlice.reducer
