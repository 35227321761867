import React from 'react'
import { USERROLES } from 'src/constant'
import AdminDashboard from './AdminDashboard'
import BusinessUserDashboard from './BusinessUserDashboard'
import CandidateDashboard from './candidate/CandidateDashboard'

const Dashboard = () => {
  let userRole = JSON.parse(localStorage.getItem('user'))?.userInfo.userRole
  const loadDashboard = () => {
    if (userRole === USERROLES[0]) return <BusinessUserDashboard />
    if (userRole === USERROLES[1]) return <AdminDashboard />
    if (userRole === USERROLES[2]) return <CandidateDashboard />
    if (userRole === USERROLES[3]) return <AdminDashboard />
  }

  return userRole && loadDashboard()
}

export default Dashboard
