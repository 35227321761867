import React, { createContext, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage } from './useLocalStorage'
import { USERROLES } from 'src/constant'
import { BroadcastChannel } from 'broadcast-channel'

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage('user', null)
  const navigate = useNavigate()
  const logoutChannel = new BroadcastChannel('logout')
  const loginChannel = new BroadcastChannel('login')

  const login = async (data) => {
    console.log('data...', data)
    setUser(data)
    let dashboardRoute = ''
    if (data.userInfo.userRole === USERROLES[0])
      dashboardRoute = data.userInfo.package ? '/business/benefit/' : '/business-user/dashboard/'
    if (data.userInfo.userRole === USERROLES[1]) dashboardRoute = '/super-admin/dashboard/'
    if (data.userInfo.userRole === USERROLES[2]) dashboardRoute = '/kandidat/dashboard/'
    if (data.userInfo.userRole === USERROLES[3])
      dashboardRoute = '/costumer-success/corporate-benefit/einloesestellen/'
    loginChannel.postMessage('Login')
    navigate(dashboardRoute, { replace: true })
  }

  const logout = () => {
    setUser(null)
    window.localStorage.setItem('i18nextLng', null)
    window.sessionStorage.clear()
    logoutChannel.postMessage('Logout')
    navigate('/login', { replace: true })
  }

  const logoutAllTabs = () => {
    logoutChannel.onmessage = () => {
      logout()
      logoutChannel.close()
    }
  }

  const loginAllTabs = () => {
    loginChannel.onmessage = () => {
      const user = JSON.parse(localStorage.getItem('user'))
      login(user)
      loginChannel.close()
    }
  }

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      logoutAllTabs,
      loginAllTabs,
    }),
    [user],
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}
