import axios from 'axios'
import { axiosInstance } from 'src/config/https'

export const getResult = (url) => {
  return axiosInstance.get(url)
}

export const postData = (url, payload) => {
  return axiosInstance.post(url, payload)
}

export const updateData = (url, payload) => {
  return axiosInstance.put(url, payload)
}

export const deleteData = (url, payload = null) => {
  return axiosInstance.delete(url, payload)
}
